import * as React from "react"
import { withStyles, Container, Grid } from '@material-ui/core/';
import one from '../images/one.png';
import two from '../images/two.png';
import three from '../images/three.png';
import four from '../images/four.png';

const SupportSection = ({ classes }) => (
  <div>
    <Container>
      <Grid container spacing={2}>
        <Grid item md={3} lg={3} xs={12} sm={12}>
          <img src={one} style={{ width: '100%' }} />
        </Grid>
        <Grid item md={3} lg={3} xs={12} sm={12}>
          <img src={two} style={{ width: '100%' }} />
        </Grid>
        <Grid item md={3} lg={3} xs={12} sm={12}>
          <img src={three} style={{ width: '100%' }} />
        </Grid>
        <Grid item md={3} lg={3} xs={12} sm={12}>
          <img src={four} style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Container>
  </div>
)

const styles = (theme) => ({

})

export default withStyles(styles, { withTheme: true })(SupportSection)
