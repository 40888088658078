import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { withStyles, Box, Container, Typography } from '@material-ui/core/';
import Layout from "../components/layout"
// import Seo from "../components/seo"
import CartContent from "../components/CartContent"
import Categories from "../components/Categories"
import ProductList from "../components/ProductList"
import SupportSection from "../components/SupportSection"
import { Page } from "../components";
import { useCartData, useAuthData } from '../helpers';

const CartPage = () => {
  const [cartList, setCartList] = React.useState([]);
  const cartData = useCartData();
  const authData = useAuthData();
  console.log('authData', authData)

  React.useEffect(() => {
    setCartList(cartData?.cartDataList);
  }, [cartData?.cartDataList])

  React.useEffect(() => {
    if (authData?.isLoggedIn) {
      cartData?.fetchCartApi();
    }
  }, [authData?.isLoggedIn])

  return (
    <Layout>
      <Page>
        <Box paddingY={5}>
          <Container>
            {cartList?.length > 0 ? <Typography variant='h4'>My Shopping Cart</Typography> : ''}
            <Box paddingTop={5}>
              <CartContent cartList={cartList} />
            </Box>
          </Container>
        </Box>
      </Page>
    </Layout>
  )
}

export default CartPage
